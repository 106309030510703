<template>
    <el-card>
        <el-row :gutter="20">
            <el-col :span="12">
                <!-- 搜索与添加 -->
                <el-input 
                    v-model="query"
                    @clear="clearQuery"
                    placeholder="请输入内容" 
                    class="input-with-select" 
                    clearable>
                    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
            </el-col>

            <el-col :span="4">
                <el-button type="primary" @click="addressDialogVisible = true">添加地区</el-button>
            </el-col>
        </el-row>
        
        <!-- 地区 -->
        <el-tabs class="mt20" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.name" :name=" '' + item.id" v-for="item in tabs" :key="item.id">    
                <el-scrollbar style="width:100%; height:500px">
                    <el-table
                        :tree-props="{hasChildren: 'hasChildren'}"
                        :data="region"
                        :load="load"
                        lazy
                        row-key="id"
                        size="small">
                        <el-table-column label="地区" prop="name"></el-table-column>
                        <el-table-column label="状态">
                            <template v-slot="scope">
                                <el-switch v-model="scope.row.status" @change="change({id: scope.row.id, status: scope.row.status ? 1 : 0})"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="热门城市">
                            <template v-slot="scope">
                                <el-switch v-model="scope.row.hot" @change="change({id: scope.row.id, hot: scope.row.hot ? 1 : 0})"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="等级">
                            <template v-slot="scope">
                                <el-tag v-if="scope.row.level === 1">{{scope.row.level}}</el-tag>
                                <el-tag size="small" type="warning" v-else-if="scope.row.level === 2">{{scope.row.level}}</el-tag>
                                <el-tag size="mini" type="danger" v-else>{{scope.row.level}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template v-slot="scope">
                                <el-button type="primary" icon="el-icon-edit" size="mini" @click="getCurrent(scope.row)">{{$t('button.edit')}}</el-button>
                                <el-button type="danger" icon="el-icon-delete" size="mini" @click="remove(scope.row.id)">{{$t('button.del')}}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-scrollbar>
            </el-tab-pane>

            <el-tab-pane label="其他国家" name="all">敬请期待</el-tab-pane>
        </el-tabs>

        <!-- 添加地区数据对话框 -->

        <el-dialog
            title="添加地区"
            :visible.sync="addressDialogVisible"
            width="50%">
            <create-form 
                ref="add"
                size='medium'
                :createData="addData"
                :createForm="addForm"
                :rule="fromRules">
            </create-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addressDialogVisible = false">{{$t('button.cancel')}}</el-button>
                <el-button type="primary" @click="add">{{$t('button.ok')}}</el-button>
            </span>
        </el-dialog>

        <!-- 修改地址对话框 -->

        <el-dialog
            :title="$t('button.edit')"
            :visible.sync="editDialogVisible"
            width="50%">
            <create-form 
                ref="edit"
                size='medium'
                :createData.sync="editData"
                :createForm.sync="editForm"
                :rule="fromRules">
            </create-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">{{$t('button.cancel')}}</el-button>
                <el-button type="primary" @click="edit">{{$t('button.ok')}}</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
// import cityData from '@/assets/js/citydata.js'
import { getRegion, editRegion, changeRegion, parentdata,regionAll} from '@/api/console/admin'

import CreateForm from '@/components/Form'
let level = [
    {label: '省', value: 1},
    {label: '市', value: 2},
    {label: '区/县', value: 3}
]

export default {
    data() {
        return {
            // 搜索
            query: '',
            // Tab标签
            tabs: [],
            // 默认 Tab
            activeName: '100000',
            // table 地区
            region: [],
            // add 对话框
            addressDialogVisible: false,
            addData: {},
            // edit 对话框
            editDialogVisible: false,
            editData: {},
            propsRegion: {
                value: 'id',
                label: 'name',
                children: 'children',
                checkStrictly: true
            },
            // 级联选择器数据
            cityData: []
        }
    },
    components: {
        CreateForm,
    },
    computed: {
        // add
        addForm() {
            return [
                { 
                    type: 'Input',
                    label: '地区',
                    prop: 'name',
                    placeholder: this.$t('form.region.name')
                },
                { 
                    type: 'Radio',
                    label: '等级',
                    prop: 'level',
                    props: {label: 'label', value: 'value'},
                    radios: level,
                    change: this.levelChange,
                    placeholder: this.$t('form.region.level')
                },
                { 
                    type: 'Cascader',
                    label: '选择父级',
                    prop: 'fid',
                    options: this.cityData,
                    props: this.propsRegion,
                    placeholder: '选择父级'
                },
                { 
                    type: 'Input',
                    label: '行政代码',
                    prop: 'code',
                    placeholder: this.$t('form.region.code')
                },
                { 
                    type: 'Switch',
                    label: '状态',
                    prop: 'status'
                },
                { 
                    type: 'Switch',
                    label: '热门城市',
                    prop: 'hot'
                }
            ]
        },
        editForm() {
            return [
                { 
                    type: 'Input',
                    label: '地区',
                    prop: 'name', 
                    placeholder: this.$t('form.region.name')
                },
                { 
                    type: 'Input', 
                    label: '父级', 
                    prop: 'id',
                    placeholder: this.$t('form.region.name')
                },
                { 
                    type: 'Radio',
                    label: '等级',
                    prop: 'level',
                    props: {label: 'label', value: 'value'},
                    radios: level,
                    change: this.levelChange,
                    placeholder: this.$t('form.region.level')
                },
                { 
                    type: 'Cascader',
                    label: '选择父级',
                    prop: 'fid',
                    options: this.cityData,
                    props: this.propsRegion,
                    placeholder: '选择父级'
                },
                { 
                    type: 'Input', 
                    label: '行政代码',
                    prop: 'code',
                    placeholder: this.$t('form.region.code')
                },
                { 
                    type: 'Switch', 
                    label: '状态',
                    prop: 'status'
                },
                { 
                    type: 'Switch', 
                    label: '热门城市',
                    prop: 'hot'
                }
            ]
        },
        // 表单验证
        fromRules() {
            const rules = {
                name: [
                    { required: true, message: this.$t('validate.empty'), trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ],
                level: [
                    { required: true, message: this.$t('validate.empty_radio'), trigger: 'blur' },
                ]
            }
            return rules
        }
    },
    async created() {
        this.tabs = await this.getRegionList()
    },
    mounted() {
        this.getAll()
    },
    methods: {
        getAll() {
            regionAll().then(res =>{
                // console.log('getAll',res.data.data.list)
                this.cityData = res.data.data
                this.cityData[0].children.forEach((e, i) => {
                    this.region.push({
                        id: e.id,
                        name: e.name,
                        code: e.code,
                        fid: e.fid,
                        level: e.level,
                        hot: e.hot ? true : false,
                        status: e.status ? true : false,
                        hasChildren: e.level === 3 ? false : true
                    })
                })
            })
        },

        // 清空搜索
        async clearQuery() {
            this.query = ''
            let data = await this.getRegionList({fid: this.activeName})
            this.region = data
        },

        // 搜索
        async search() {
            if(this.query) {
                let data = await this.getRegionList({query: this.query})
                data.forEach(e => {
                    e.hasChildren = false
                })

                return this.region = data
            }
            this.$message.warning(this.$t('tips.search'))
        },

        // 切换选项标签
        handleClick() {
            
        },
        
        // 动态获取数据列表
        getRegionList(data) {
            return new Promise(resolve => {
                getRegion(data).then(res => {
                    let list = res.data.data
                    list.forEach(element => {
                        element.status = element.status ? true : false
                        element.hot = element.hot ? true : false
                        element.hasChildren = element.level === 3 ? false : true
                    })
                    resolve(list)
                })
            })
        },

        // 表单动态加载
        async load(tree, treeNode, resolve) {
            let data = await this.getRegionList({fid: tree.id})
            resolve(data)
        },

        levelChange(level) {
            console.log('levelChange',level)
            if(this.editData.fid.length) {
                    
            }
        },

        // 编辑当前点击的
        getCurrent(data) {
            this.editData = JSON.parse(JSON.stringify(data))
            this.editDialogVisible = true
        },

        // 状态切换
        change(data) {
            changeRegion(data).then(res => {
                this.$message.success(this.$t('tips.success'))
            })
        },
        
        // 添加
        add() {

        },

        // 修改
        edit() {
            
        },

        // 删除
        remove(data) {

        }
    }
}
</script>